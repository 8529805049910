<script>
import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    groupLogonMasterProp: Object
  },
  components: {
    AppCard
  },
  data() {
    return {
      moduleMap: {
        entryJournal: 'ป้อนรายการสมุดรายวัน',
        entryJournalTemplate: 'ต้นแบบป้อนรายการสมุดรายวัน',
        registrationMaster: 'งานทะเบียน-ผังบัญชี',
        reportBalanceSheet: 'ออกรายงานงบดุล',
        reportProfitAndLoss: 'ออกรายงานงบกำไรขาดทุน',
        reportTrialBalance: 'ออกรายงานงบทดลอง',
        reportWorkingPaper: 'ออกรายงานกระดาษทำการ',
        registrationAR: 'งานทะเบียน-ลูกค้าลูกหนี้',
        reportAccountActivity: 'ออกรายงานสรุปผลบัญชีแยกประเภท'
      },
      fieldMap: {
        canView: 'เข้าดู',
        canAddNew: 'สร้าง',
        canUpdate: 'แก้ไข',
        canDelete: 'ลบ',
        canPrint: 'พิมพ์',
        canApprove: 'อนุมัติ',
        canCancel: 'ยกเลิก',
        canEx1: 'อื่น ๆ 1',
        canEx2: 'อื่น ๆ 2'
      }
    }
  }
}
</script>

<template>
  <div class="p-2">
    <app-card>
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold"> สิทธิ์การเข้าถึง </span>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="!groupLogonMasterProp">
        <b-col>
          <span>ไม่พบสิทธิ์การเข้าถึงใด ๆ ที่เกี่ยวข้อง</span>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-else>
        <b-col
          cols="12"
          v-for="(permission, name, index) in groupLogonMasterProp.permission"
          :key="index"
        >
          <b-row class="d-flex align-items-center my-1">
            <b-col cols="3">
              <span>{{ moduleMap[name] }}</span>
            </b-col>
            <b-col cols="9">
              <b-button
                class="m-1"
                v-for="(allow, key) in permission"
                :key="key"
                :variant="!!allow ? 'primary' : 'outline-primary'"
                size="sm"
                disabled
              >
                {{ fieldMap[key] }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
