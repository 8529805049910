<script>
import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    groupLogonMasterProp: Object
  },
  components: {
    AppCard
  },
  data() {
    return {
      members: []
    }
  },
  watch: {
    groupLogonMasterProp() {
      this.setMembers()
    }
  },
  created() {
    this.setMembers()
  },
  methods: {
    setMembers() {
      if (this.groupLogonMasterProp) {
        this.members = [...this.groupLogonMasterProp.users]
      }
    }
  }
}
</script>

<template>
  <div class="p-2 h-100">
    <app-card class="h-100">
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">
            ผู้ใช้งาน
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col v-if="members.length > 0">
          <b-badge
            class="py-2 px-3 mr-2 mb-2"
            variant="primary"
            v-for="(member, index) in members"
            :key="index"
          >
            {{ member.userName + ' (' + member.userId + ')' }}
          </b-badge>
        </b-col>
        <b-col v-else>
          <span class="text-md">ไม่มีผู้ใช้งานรายใดเกี่ยวข้องกับกลุ่มนี้</span>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
