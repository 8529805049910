<script>
import { toastMixins, datetimeMixins } from '@/mixins'

import authService from '@/services/auth/auth'

import MenuBar from './components/MenuBar.vue'
import InformationPanel from './components/InformationPanel.vue'
import MemberPanel from './components/MemberPanel.vue'
import PermissionPanel from './components/PermissionPanel.vue'

export default {
  mixins: [toastMixins, datetimeMixins],
  components: {
    MenuBar,
    InformationPanel,
    MemberPanel,
    PermissionPanel
  },
  data() {
    return {
      groupCode: null,
      groupLogonMaster: null,
      errMsg: null
    }
  },
  created() {
    this.groupCode = this.$route.params.groupCode
    this.fetchGroupLogonMasterDetail()
  },
  methods: {
    async fetchGroupLogonMasterDetail() {
      try {
        const res = await authService.getGroupLogonMaster(this.groupCode)
        this.groupLogonMaster = this.formatResult(res.data.data)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
    },
    formatResult(groupLogonMaster) {
      const { createdAt, updatedAt } = groupLogonMaster
      return {
        ...groupLogonMaster,
        createdAtFormat: this.mxConvertToDatetimeBuddhistFormat(createdAt),
        updatedAtFormat: this.mxConvertToDatetimeBuddhistFormat(updatedAt)
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="รายละเอียดกลุ่มผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar :groupLogonMasterProp="groupLogonMaster"></menu-bar>
      </b-col>
    </b-row>

    <b-row>
      <!-- information -->
      <b-col cols="12" lg="6">
        <information-panel
          :groupLogonMasterProp="groupLogonMaster"
        ></information-panel>
      </b-col>

      <!-- group -->
      <b-col cols="12" lg="6">
        <member-panel :groupLogonMasterProp="groupLogonMaster"></member-panel>
      </b-col>

      <!-- permission -->
      <b-col cols="12">
        <permission-panel
          :groupLogonMasterProp="groupLogonMaster"
        ></permission-panel>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
