<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'

import DeleteModal from '../../components/DeleteModal.vue'

export default {
  mixins: [toastMixins],
  props: {
    groupLogonMasterProp: Object
  },
  components: { DeleteModal },
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2',
      modalShow: false,
      modalTitle: null,
      modalThemeColor: null,
      modalCurrent: null,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'UPDATE':
          if (!this.groupLogonMasterProp) {
            alert('เลือกกลุ่มผู้ใช้งานที่ต้องการจะแก้ไข')
            return
          }
          this.$router.push(
            `/admin/group-logon-masters/edit/${this.groupLogonMasterProp.groupCode}`
          )
          break
        case 'DELETE':
          this.modalShow = true
          this.modalTitle = 'ลบกลุ่มผู้ใช้งาน'
          this.modalThemeColor = 'danger'
          this.modalCurrent = 'delete-modal'
          break
        case 'EXIT':
          this.$router.push({
            name: 'Admin.GroupLogonMasters'
          })
          break
      }
    },
    closeModalHandler(event) {
      switch (event.type) {
        case 'CLOSE':
          this.modalShow = false
          break
        case 'CLOSE_WITH_SUCCESS':
          this.mxDisplaySuccessMessage(event.message)
          this.modalShow = false
          setTimeout(() => {
            this.$router.replace({
              name: 'Admin.GroupLogonMasters'
            })
          }, this.defaultDelayMs * 0.7)
          break
        case 'DISPLAY_ERROR':
          this.errMsg = event.message
          break
        default:
          this.modalShow = false
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- UPDATE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขข้อมูลกลุ่มผู้ใช้งาน'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- DELETE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบกลุ่มผู้ใช้งาน'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="trash"></b-icon>
          <span class="text-sm">ลบ</span>
        </b-button>
      </div>

      <!-- EXIT BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>

      <!-- MODAL DISPLAY -->
      <b-modal
        v-model="modalShow"
        :title="modalTitle"
        :header-bg-variant="modalThemeColor"
        header-text-variant="light"
        :hide-footer="true"
        :size="'md'"
      >
        <component
          :is="modalCurrent"
          :groupLogonMasterProp="groupLogonMasterProp"
          @closeModal="closeModalHandler"
        ></component>
      </b-modal>
    </div>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
