<script>
import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    groupLogonMasterProp: Object
  },
  components: {
    AppCard
  },
  data() {
    return {
      fields: [
        { key: 'groupCode', label: 'กลุ่มผู้ใช้งาน' },
        { key: 'groupName', label: 'ชื่อกลุ่มผู้ใช้งาน' },
        { key: 'createdAtFormat', label: 'สร้างเมื่อ' },
        { key: 'updatedAtFormat', label: 'แก้ไขล่าสุดเมื่อ' }
      ]
    }
  }
}
</script>

<template>
  <div class="p-2 h-100">
    <app-card class="h-100">
      <b-row>
        <b-col>
          <span class="text-lg font-weight-bold">
            ข้อมูลรายละเอียด
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-row v-for="field in fields" :key="field.key" class="mt-2">
            <b-col cols="4">
              <span>
                {{ field.label }}
              </span>
            </b-col>
            <b-col cols="8"
              ><span>
                {{
                  (groupLogonMasterProp && groupLogonMasterProp[field.key]) ||
                    '-'
                }}
              </span></b-col
            >
          </b-row>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
